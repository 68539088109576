<template>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a id="backBtn" class="btn p-0 mr-md-4">
          <i class="fa fa-arrow-left text-light h5 mb-0"></i>
        </a>
        <span class="navbar-brand bg-transparent rounded p-0 text-center mr-0 mr-md-5">
          <span class="h6 font-weight-bold text-truncate">Stok - Riwayat</span>
        </span>
        <a class="btn px-0 d-block d-md-none" data-toggle="collapse" data-target="#collapsibleNavbar">
          <i class="fa fa-ellipsis-h text-light"></i>
        </a>
        <div class="navbar-collapse collapse row p-3 p-md-0 mt-2 mt-md-0" id="collapsibleNavbar">
          <ul class="navbar-nav w-100">
            <li class="nav-item mt-1 mb-n1">
              <a href="./jual-stok.html" class="nav-link" title="Daftar Stok Bahan">Daftar Stok Bahan</a>
            </li>
            <li class="nav-item mt-1 mb-n1">
              <a href="./jual-supplier.html" class="nav-link" title="Supplier">Supplier</a>
            </li>
            <li class="nav-item mt-1 mb-n1 active">
              <a href="./jual-stok-riwayat.html" class="nav-link" title="Riwayat Pembelian">Riwayat Pembelian</a>
            </li>
          </ul>
        </div>
      </nav>
    </div><!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">

        <div class="row d-none">
          <div class="col-12">
            <div class="alert alert-secondary bg-light alert-dismissible fade show" role="alert">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <strong>Alert!</strong> You should <a href="javascript:void(0);" class="alert-link" title="Click to go to this message">read this message</a>.
              <button id="submitButton" class="btn btn-sm btn-light border py-0 mt-n1" type="submit" title="Action..."><i class="fa fa-user mr-1"></i>Action</button>
            </div>
          </div> <!-- .col -->
        </div> <!-- .row -->

        <div class="row mt-3 mb-2">
          <div class="col-12">
            <select class="form-control form-control-sm" id="sel1" required>
              <option value="">Semua...</option>
              <option value="1" selected>Aktif</option>
              <option value="2">Inaktif</option>
            </select>
          </div> <!-- .col -->
        </div> <!-- .row -->

        <div class="input-group input-group-sm mb-3">
          <input type="text" class="form-control" placeholder="Cari...">
          <div class="input-group-append">
            <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
          </div>
        </div>

        <div class="row mt-3 mb-5">
          <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <div class="card mb-3">
              <div class="card-body p-0">
                <div class="float-left w-25 text-center">
                  <i class="fa fa-exchange h1 mt-2 text-secondary"></i>
                </div>
                <div class="float-right w-75 p-2 pl-3">
                  <a href="./jual-supplier-info.html" class="card-title text-main font-weight-bold d-block mb-1 mt-1 card-link stretched-link text-truncate">???</a>
                  <p class="card-text mb-1 text-truncate">???</p>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div><!-- .col -->

        </div><!-- .row -->
  
        <a href="./jual-supplier-tambah.html" id="btn-float" class="bg-warning position-fixed rounded-circle shadow text-dark text-center"><i class="fa fa-plus h5 mt-3"></i></a>
  
      </div><!-- main-content -->
    </div><!-- .row -->
  </div><!-- .container -->
</template>